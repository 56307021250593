import { render, staticRenderFns } from "./TwoFactorVerification.vue?vue&type=template&id=464d9146&scoped=true&"
import script from "./TwoFactorVerification.vue?vue&type=script&lang=js&"
export * from "./TwoFactorVerification.vue?vue&type=script&lang=js&"
import style0 from "./TwoFactorVerification.vue?vue&type=style&index=0&id=464d9146&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "464d9146",
  null
  
)

export default component.exports