<script>
import Vue from 'vue';
import { storeApi } from '@shared/config/axios';

export default {
  name: 'TwoFactorVerification',
  i18n: {
    messages: {
      en: {
        auth: {
          two_factor: {
            title: 'Two-step verification',
            subtitle: 'To proceed, please enter the verification code that has been sent to you',
            code: 'Verification code',
            code_placeholder: 'Enter the 6-digit code',
            verify: 'Verify',
            help_text: 'You didn\'t receive the code?',
            resend_code: 'Resend code',
            invalid_code: 'The code entered is not valid',
            verification_failed: 'Verification failed. Please try again.',
            code_resent: 'A new code has been sent to you',
            resend_failed: 'Failed to send the code. Please try again.',
          },
        },
      },
      fr: {
        auth: {
          two_factor: {
            title: 'Authentification à 2 facteurs',
            subtitle: 'Pour continuer, veuillez entrer le code de vérification qui vous a été envoyé par email',
            code: 'Code de vérification',
            code_placeholder: 'Entrez le code à 6 chiffres',
            verify: 'Vérifier',
            help_text: 'Vous n\'avez pas reçu le code ?',
            resend_code: 'Renvoyer le code',
            invalid_code: 'Le code entré n\'est pas valide',
            verification_failed: 'La vérification a échoué. Veuillez réessayer.',
            code_resent: 'Un nouveau code vous a été envoyé',
            resend_failed: 'L\'envoi du code a échoué. Veuillez réessayer.',
          },
        },
      },
    },
  },

  data() {
    return {
      code: '',
      loading: false,
      error: null,
      countdown: 0,
      countdownInterval: null,
    };
  },

  computed: {
    canResendCode() {
      return this.countdown === 0 && !this.loading;
    },
    countdownText() {
      return this.countdown > 0 ? `(${this.countdown}s)` : '';
    },
  },

  beforeDestroy() {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
  },

  mounted() {
    this.resendCode(); // Generate code when component is mounted
  },

  methods: {
    startCountdown() {
      this.countdown = 60;
      if (this.countdownInterval) {
        clearInterval(this.countdownInterval);
      }
      this.countdownInterval = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(this.countdownInterval);
        }
      }, 1000);
    },

    async verifyCode() {
      this.loading = true;
      this.error = null;

      try {
        const response = await storeApi.post('/customers/me/two-factor/verify', {
          code: this.code,
        });

        // console.log(response);

        if (response.status === 200) {
          this.$auth.token(response.data.token);
          console.log(response.data.token);
          // Update the user object to reflect verified 2FA
          const user = Vue.auth.user();
          user.two_factor = {
            ...user.two_factor,
            verified: true,
          };
          Vue.auth.user(user);

          // Redirect to the intended destination or dashboard
          const redirect = this.$route.query.redirect || { name: 'dashboard' };
          this.$router.push(redirect);
        } else {
          this.error = this.$t('auth.two_factor.invalid_code');
        }
      } catch (error) {
        this.error = error.response?.data?.message || this.$t('auth.two_factor.verification_failed');
      } finally {
        this.loading = false;
      }
    },

    async resendCode() {
      if (!this.canResendCode) return;

      this.loading = true;
      this.error = null;

      try {
        await storeApi.$post('/customers/me/two-factor/generate');
        this.$buefy.notification.open({
          message: this.$t('auth.two_factor.code_resent'),
          type: 'is-success',
        });
        this.startCountdown();
      } catch (error) {
        this.error = error.response?.data?.message || this.$t('auth.two_factor.resend_failed');
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<template>
  <div class="two-factor-verification">
    <div class="card">
      <div class="card-content">
        <h1 class="title is-4">
          {{ $t('auth.two_factor.title') }}
        </h1>
        <p class="subtitle is-6 mb-4">{{ $t('auth.two_factor.subtitle') }}</p>

        <form @submit.prevent="verifyCode" class="mb-4">
          <b-field :label="$t('auth.two_factor.code')" :type="{ 'is-danger': error }" :message="error">
            <b-input v-model="code" type="text" :placeholder="$t('auth.two_factor.code_placeholder')" maxlength="6"
              :disabled="loading" @input="error = null" required />
          </b-field>

          <div class="buttons">
            <b-button type="is-primary" :loading="loading" native-type="submit" expanded>
              {{ $t('auth.two_factor.verify') }}
            </b-button>
          </div>
        </form>

        <div class="has-text-centered">
          <p class="is-size-7">
            {{ $t('auth.two_factor.help_text') }}
            <a @click="resendCode" :class="{ 'is-disabled': !canResendCode }"
              :style="!canResendCode ? 'cursor: not-allowed; opacity: 0.5;' : ''">
              {{ $t('auth.two_factor.resend_code') }} {{ countdownText }}
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.two-factor-verification {
  max-width: 480px;
  margin: 2rem auto;
  padding: 0 1rem;
}

.card {
  border-radius: 8px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1);
}

.card-content {
  padding: 2rem;
}
</style>
